import cn from 'classnames';

import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';
import {ColorSchemeEnum} from '@/enums';
import useCtasOrSignup from '@/hooks/useCtasOrSignup/useCtasOrSignup';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';

interface HeroFloatingMediaProps extends HeroProps {
  mediaComponent?: React.ReactNode;
}

export default function HeroFloatingMedia({
  className,
  colorScheme,
  ctas,
  headingHtml,
  images,
  kicker,
  mode = 'light',
  signup: signupData,
  subheadHtml,
  videos,
  isBleedImage,
  mediaComponent,
  displayPromoBanner = false,
  isPillarPage = false,
  ...props
}: HeroFloatingMediaProps) {
  let buttonGroupProps = useCtasOrSignup(signupData || {}, ctas);
  buttonGroupProps = {
    ...buttonGroupProps,
    buttons: buttonGroupProps ? buttonGroupProps.buttons : [], // ensure buttons is always an array
    displayPromoBanner: displayPromoBanner,
    isPillarPage: isPillarPage,
  };
  const scheme = colorScheme || ColorSchemeEnum.Lavender;
  const image = images && images.length > 0 ? images[0] : undefined;
  const video = videos && videos.length > 0 ? videos[0] : undefined;

  return (
    <Section
      className={twMerge(
        cn('relative pt-hero-top pb-3xl md:pb-0', {
          'pb-0': isBleedImage,
        }),
        heroBackgroundByTheme[scheme],
        className,
      )}
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      {...props}
    >
      <TwoColumn>
        <TwoColumn.Col1 className="copy-container flex flex-col pt-3xl sm:col-span-6 my-auto md:py-3xl gap-y-md">
          <IncentivesPromoBanner mode="dark" />
          <HeadingGroup
            kicker={kicker as string}
            headingAs="h1"
            headingHtml={headingHtml as string}
            subheadHtml={subheadHtml as string}
            size="t1"
            mode={mode}
          />
          {buttonGroupProps && (
            <ButtonGroup mode={mode} {...buttonGroupProps} />
          )}
        </TwoColumn.Col1>
        <TwoColumn.Col2
          className={cn(
            'media-container my-auto sm:col-start-1 sm:col-span-8 md:py-3xl',
            {
              '!py-0 flex-row h-full items-end': isBleedImage,
            },
          )}
        >
          {image && (
            <Image {...image} className={twMerge('mx-auto', image.className)} />
          )}
          {video && <WistiaVideoPlayer {...video} />}
          {mediaComponent}
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
